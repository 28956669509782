<template>
  <div class="ui stackable grid row dimmed savings">
    <div class="ui sixteen wide column col-xs-12">
      <div class="ui container">
        <div class="ui secondary pointing menu tabs" v-if="$config.whitelabel.features.savingsTabsEnabled">
          <router-link
            class="item tab"
            :class="{ active: index === 0 || isCurrentRouteActive('savings-services', 0) }"
            @click.native="setActiveIndex(0)"
            :to="{ name: 'savings-services' }"
            >Services</router-link
          >
          <router-link
            class="item tab"
            :class="{ active: index === 1 || isCurrentRouteActive('savings-banking', 1) }"
            @click.native="setActiveIndex(1)"
            :to="{ name: 'savings-banking' }"
            >Banking</router-link
          >
        </div>

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sidebarGetters } from '../../store/modules/sidebar/routines'
export default {
  name: 'Savings',
  data() {
    return {
      index: 0
    }
  },
  computed: {
    ...mapGetters({
      isSideBarOpen: sidebarGetters.SIDEBAR_STATUS
    }),
    isCompanyStatusComplete() {
      return this.companyState.fundingApplicationState === 'Completed' && this.companyState.companyState === 'Completed' && this.companyState.financialState === 'Completed'
    },
    isOpenBakingDashboard() {
      return this.isBusinessDashboardSelected ? false : this.isUserOpenBanking
    }
  },
  methods: {
    setActiveIndex(index) {
      this.index = index
    },
    isCurrentRouteActive(routeName, index) {
      if (this.$route.name.replace(/static-/gi, '') === routeName) {
        this.index = index
      }
      return this.$route.name === routeName
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';
.funding-page {
  min-height: 100%;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
</style>
